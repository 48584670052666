import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// utils
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { defaultLang } from './config-lang';
//
import enTranslations from './langs/en/translation.json';
import nlTranslations from './langs/nl/translation.json';
import frTranslations from './langs/fr/translation.json';
import nlInfographics from './langs/nl/infographics.json';
import enInfographics from './langs/en/infographics.json';
import frInfographics from './langs/fr/infographics.json';
import { initializeYupLocale } from './yup';

// ----------------------------------------------------------------------

const storageAvailable = localStorageAvailable();
const lng = storageAvailable ? (localStorage.getItem('i18nextLng') || defaultLang.value) : defaultLang.value;

initializeYupLocale();

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enTranslations, infographics: enInfographics },
      nl: { translations: nlTranslations, infographics: nlInfographics },
      fr: { translations: frTranslations, infographics: frInfographics },
    },
    lng,
    fallbackLng: defaultLang.value,
    debug: false,
    ns: ['translations', 'infographics'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
