import { FC } from 'react';
import { CssBaseline } from '@mui/material';
import {
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
import GlobalStyles from './globalStyles';
import baseTheme from './BaseTheme/BaseTheme';

type Props = {
  children: React.ReactNode;
};

const ThemeProvider: FC<Props> = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <MUIThemeProvider theme={ baseTheme }>
      <CssBaseline />
      <GlobalStyles />
      {children}
    </MUIThemeProvider>
  </StyledEngineProvider>
);

export default ThemeProvider;
