import { forwardRef } from 'react';
import { Box, BoxProps, useTheme } from '@mui/material';
import { ReactComponent as GrowthSpotLogoText } from './svgs/logo_gs_text.svg';
import Link from '../Link/Link';

// ----------------------------------------------------------------------

export interface TextLogoProps extends BoxProps {
  disabledLink?: boolean;
  positive?: boolean;
  variant?: 'positive' | 'negative';
}

const TextLogo = forwardRef<HTMLDivElement, TextLogoProps>(
  ({ disabledLink = false, sx, positive = true, variant = 'positive', ...other }, ref) => {
    const theme = useTheme();
    const fillOverwritten = !!theme.palette.gs.navigation.logo.fill;
    const logoStyleOverwrite = {
      '*': {
        fill: '#17365C'
      }
    }

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          ...sx,
          ...(fillOverwritten && logoStyleOverwrite)
        }}
        {...other}
      >
        { variant === 'positive' ? <GrowthSpotLogoText height="40px" /> : <GrowthSpotLogoText height="40px" /> }
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link to="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default TextLogo;
