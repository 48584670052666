import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router';
import calendar from 'dayjs/plugin/calendar';
import dayjs from 'dayjs';
import * as Sentry from "@sentry/react";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import router from './App.router';
import "yup-phone";
import { initializeMsalInstance } from './auth/msalInstance';

dayjs.extend(calendar)

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_API === '1') {
    /* eslint-disable */
    const { worker } = require('./mocks/browser');
    worker.start()
    /* eslint-enable */
}

if (process.env.REACT_APP_SENTRY_ENVIRONMENT !== 'local') {
  Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    dsn: "https://f0c7b211695607852cc2caa82a64cf67@o4506616671305728.ingest.sentry.io/4506616672681984",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/dev\.growth-spot\.com/,
          /^https:\/\/platform\.growth-spot\.com/,
          /^https:\/\/dev\.impetus\.academy/,
          /^https:\/\/platform\.impetus\.academy/,
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

initializeMsalInstance();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <RouterProvider router={ router } />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
