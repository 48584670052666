import { forwardRef } from 'react';
import { Box, BoxProps } from '@mui/material';
import { ReactComponent as GrowthSpotLogoSmallCircle } from './svgs/logo_gs_small_circle.svg';
import Link from '../Link/Link';

// ----------------------------------------------------------------------

export interface SmallCircleLogoProps extends BoxProps {
  disabledLink?: boolean;
  positive?: boolean;
  variant?: 'positive' | 'negative';
}

const SmallCircleLogo = forwardRef<HTMLDivElement, SmallCircleLogoProps>(
  ({ disabledLink = false, sx, positive = true, variant = 'positive', ...other }, ref) => {
    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          ...sx,
        }}
        {...other}
      >
        <GrowthSpotLogoSmallCircle />
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link to="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default SmallCircleLogo;