import { useTranslation } from 'react-i18next';
import { i18n as i18n_t } from 'i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/nl'
import updateLocale from 'dayjs/plugin/updateLocale'
import calendar from 'dayjs/plugin/calendar'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import localStorageAvailable from '../utils/localStorageAvailable';
import { allLangs, defaultLang } from './config-lang';



// ----------------------------------------------------------------------

export type i18translateType = {
  t: (key: string | TemplateStringsArray | (string | TemplateStringsArray)[], options?: string | undefined) => string,
  i18n: i18n_t,
}

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

dayjs.extend(updateLocale)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(calendar)
// dayjs.tz.setDefault(dayjs.tz.guess())
dayjs.updateLocale("nl", {
  calendar: {
    sameDay: '[vandaag om ] LT', // The same day ( Today at 2:30 AM )
    nextDay: '[morgen]', // The next day ( Tomorrow at 2:30 AM )
    nextWeek: 'dddd', // The next week ( Sunday at 2:30 AM )
    lastDay: '[gisteren]', // The day before ( Yesterday at 2:30 AM )
    lastWeek: '[vorige] dddd', // Last week ( Last Monday at 2:30 AM )
    sameElse: 'DD/MM/YYYY' // Everything else ( 7/10/2011 )
  }
})

export default function useLocales(ns?: string, config?: { supportedLocales?: string[] }) {
  const { t: translate, i18n }: i18translateType = useTranslation(ns);

  const storageAvailable = localStorageAvailable();

  const langStorage = storageAvailable ? localStorage.getItem('i18nextLng') : '';
  const selectedLanguage = storageAvailable ? localStorage.getItem('selectedLanguage') : undefined;

  const currentLang = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang;
  dayjs.locale(currentLang.value);

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
    if(storageAvailable) {
      localStorage.setItem('selectedLanguage', newlang)
    }
  };

  const onSuggestLang = (newlang: string) => {
    if (!selectedLanguage) {
      handleChangeLanguage(newlang);
    }
  };

  if (config?.supportedLocales?.length && !(config.supportedLocales?.includes(currentLang.value))) {
    handleChangeLanguage(config?.supportedLocales[0]);
  }

  const t = (text: any, options?: any): string => translate(text, options);

  return {
    onChangeLang: handleChangeLanguage,
    onSuggestLang,
    t,
    T: (text: any, options?: any) => capitalize(t(text, options)),
    currentLang,
    allLangs,
    capitalize,
  };
}
