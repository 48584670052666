import { forwardRef } from 'react';
import { Box, BoxProps } from '@mui/material';
import { ReactComponent as GrowthSpotLogoCircle } from './svgs/logo_gs_circle.svg';
import Link from '../Link/Link';

// ----------------------------------------------------------------------

export interface CircleLogoProps extends BoxProps {
  disabledLink?: boolean;
  positive?: boolean;
  variant?: 'positive' | 'negative';
}

const CircleLogo = forwardRef<HTMLDivElement, CircleLogoProps>(
  ({ disabledLink = false, sx, positive = true, variant = 'positive', ...other }, ref) => {
    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          ...sx,
        }}
        {...other}
      >
        <GrowthSpotLogoCircle />
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link to="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default CircleLogo;