import { ReactElement, SVGProps, cloneElement, forwardRef } from 'react';
import { Box, BoxProps, useTheme } from '@mui/material';
import { ReactComponent as GrowthSpotLogoPositive } from './svgs/logo_gs_positive.svg';
import { ReactComponent as GrowthSpotLogoNegative } from './svgs/logo_gs_negative.svg';
import Link from '../Link/Link';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  positive?: boolean;
  variant?: 'positive' | 'negative';
  Override?: ReactElement<HTMLOrSVGElement>;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(({ disabledLink = false, sx, positive = true, variant = 'positive', Override, ...other }, ref) => {
    const theme = useTheme();
    const fillOverwritten = !!theme.palette.gs.navigation.logo.fill;
    const logoStyleOverwrite = {
      '*': {
        fill: theme.palette.gs.navigation.logo.fill
      }
    }

    /* eslint-disable */
    const LogoOverride = () => Override ? cloneElement<SVGProps<SVGSVGElement>>(Override, { height: '40px'}) : null;
    const DefaultLogo = () => (variant === 'positive' ? <GrowthSpotLogoPositive height="40px" /> : <GrowthSpotLogoNegative height="40px" />);
    /* eslint-enable */

   

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          ...sx,
          ...(fillOverwritten && logoStyleOverwrite)
        }}
        {...other}
      >
        { Override ? <LogoOverride /> : <DefaultLogo /> }
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link to="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
