import { FetchArgs } from "@reduxjs/toolkit/dist/query";

const inheritQueries: { [key: string]: ((arg: any) => FetchArgs | string) } = {};

// Helper function to store the initial query function. Afterwards, it gets overriden on the endpoint.
export function inheritQuery<TArg>(queryName: string, query: ((arg: TArg) => FetchArgs | string) | undefined) {
    if (query && !(queryName in inheritQueries)) {
        inheritQueries[queryName] = query;
    }

    return inheritQueries[queryName] as (arg: TArg) => FetchArgs;
}