import { FC } from 'react';
import { Link as RouterLink, To } from 'react-router-dom';
import { LinkProps, Link as MuiLink } from '@mui/material';

interface Props extends LinkProps {
    to: To;
};
 
const Link: FC<Props> = ({ children, to, ...otherProps }) => (
    <MuiLink component={ RouterLink } to={ to } { ...otherProps }>
        { children }
    </MuiLink >
)

export default Link;