/** 
 * CSS color value or function.
 */
type ColorValue = string;

/**
 * The position of the gradient step as a percentage.
 * 
 * A value between 0 and 100.
 */
type Position = number;
type GradientStep = [ColorValue, Position];

export const generateGradient = (deg: number, ...steps: GradientStep[]) => {
    const angle = `${deg}deg`;
    const composedColorSteps = steps.map(([color, percentage]) => `${color} ${percentage}%`);
    const joinedColorSteps = composedColorSteps.join(', ');
    
    return `linear-gradient(${angle}, ${joinedColorSteps})`
};