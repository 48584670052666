import { setLocale } from "yup";

// Based on https://github.com/jquense/yup/blob/2973d0a/src/locale.js

export const initializeYupLocale = () =>
    setLocale({
        mixed: {
            default: ({ path }: any) => ({ key: "validation.mixed_default", path }),
            required: ({ path }: any) => ({ key: "validation.mixed_required", path }),
            defined: ({ path }: any) => ({ key: "validation.mixed_defined", path }),
            oneOf: ({ path, values }: any) => ({ key: "validation.mixed_oneOf", values, path }),
            notOneOf: ({ path, values }: any) => ({ key: "validation.mixed_notOneOf", path, values }),
            notType: ({ path, type, value, originalValue }: any) => ({ key: 'validation.mixed_notType', value, type, path, originalValue }),
        },
        string: {
            length: ({ path, length }: any) => ({ key: "validation.string_length", length, path }),
            min: ({ path, min }: any) => ({ key: "validation.string_min", path, min }),
            max: ({ path, max }: any) => ({ key: "validation.string_max", path, max }),
            matches: ({ path, regex }: any) => ({ key: "validation.string_matches", path, regex }),
            email: ({ path }: any) => ({ key: "validation.string_email", path }),
            url: ({ path }: any) => ({ key: "validation.string_url", path }),
            trim: ({ path }: any) => ({ key: "validation.string_trim", path }),
            lowercase: ({ path }: any) => ({ key: "validation.string_lowercase", path }),
            uppercase: ({ path }: any) => ({ key: "validation.string_uppercase", path }),
        },
        number: {
            min: ({ path, min }: any) => ({ key: "validation.number_min", path, min }),
            max: ({ path, max }: any) => ({ key: "validation.number_max", path, max }),
            lessThan: ({ path, less }: any) => ({ key: "validation.number_lessThan", path, less }),
            moreThan: ({ path, more }: any) => ({ key: "validation.number_moreThan", path, more }),
            positive: ({ path }: any) => ({ key: "validation.number_positive", path }),
            negative: ({ path }: any) => ({ key: "validation.number_negative", path }),
            integer: ({ path }: any) => ({ key: "validation.number_integer", path }),
        },
        date: {
            min: ({ path, min }: any) => ({ key: "validation.date_min", path, min }),
            max: ({ path, max }: any) => ({ key: "validation.date_max", path, max }),
        },
        boolean: {
            isValue: ({ path, value }: any) => ({ key: "validation.boolean_isValue", path, value }),
        },
        object: {
            noUnknown: ({ path, unknown }: any) => ({ key: "validation.object_noUnknown", path, unknown }),
        },
        array: {
            min: ({ path, min }: any) => ({ key: "validation.array_min", path, min }),
            max: ({ path, max }: any) => ({ key: "validation.array_max", path, max }),
        }
    });
