import { configureStore } from '@reduxjs/toolkit'
// import { keycloakApi } from './keycloakApi'
import { platformBffApi } from './platformBffApiBase'
// import { officeTemplatingApi } from './officeTemplatingApiBase'

export const store = configureStore({
  reducer: {
    [platformBffApi.reducerPath]: platformBffApi.reducer,
    // [officeTemplatingApi.reducerPath]: officeTemplatingApi.reducer,
    // [keycloakApi.reducerPath]: keycloakApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(platformBffApi.middleware),
    // .concat(keycloakApi.middleware).concat(officeTemplatingApi.middleware)
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch