import { FC, Suspense } from "react";
import LoadingScreen from "../../components/LoadingScreen";

interface Props {
  LazyImport: any;
  FallBack?: JSX.Element;
}

export const Loadable: FC<Props> = ({ LazyImport, FallBack }) => (
  <Suspense fallback={FallBack || <LoadingScreen />}>
    <LazyImport />
  </Suspense>
)