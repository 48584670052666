import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Box} from '@mui/material';
// hooks
// config
import { CircleLogo, SmallCircleLogo, TextLogo } from '../Logo';
import ProgressBar from '../ProgressBar';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 9998,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function LoadingScreen() {
  return (
    <Box sx={{
      position: 'fixed',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 100
    }}>
      <ProgressBar />

      <StyledRoot
        sx={{
          width: '100%',
        }}
      >
        <Box
          sx={{
            position: 'relative'
          }}
        >
          <Box
            component={m.div}
            animate={{
              scale: [1.2, 1.2, 1.2, 1.2, 1.2],
              opacity: [1, 0.48, 0.48, 1, 1],
            }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeatDelay: 1,
              repeat: Infinity,
            }}
            sx={{
              position: 'relative',
              zIndex: '10'
            }}
          >
            <TextLogo disabledLink />
          </Box>
          <Box
            component={m.div}
            animate={{
              scale: [0.5, 0.5, 0.5, 0.5, 0.5],
              rotate: [0, 90, 180, 270, 360],
              opacity: [1, 0.5, 0.5, 0.5, 1],
            }}
            transition={{
              duration: 2,
              ease: 'linear',
              repeat: Infinity,
            }}
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              marginLeft: '-48px',
              marginTop: '-48px'
            }}
          >
            <CircleLogo disabledLink />
          </Box>
          <Box
            component={m.div}
            animate={{
              scale: [0.45, 0.45, 0.45, 0.45, 0.45],
              rotate: [0, 90, 180, 270, 360],
              opacity: [0.6, 0.25, 0.25, 0.25, 0.7],
            }}
            transition={{
              duration: 1,
              ease: 'linear',
              repeat: Infinity,
            }}
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              marginLeft: '-48px',
              marginTop: '-48px'
            }}
          >
            <SmallCircleLogo disabledLink />
          </Box>
        </Box>
      </StyledRoot>
    </Box>
  );
}
