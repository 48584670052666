import './locales/i18n';
import 'simplebar-react/dist/simplebar.min.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useMsalAuthentication } from "@azure/msal-react";
import { useEffect } from 'react';
import { InteractionType, InteractionRequiredAuthError } from '@azure/msal-browser';
import { Outlet } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import ThemeProvider from './theme';
import ThemeLocalization from './locales';
import SnackbarProvider from './components/Snackbar';
import ScrollToTop from './components/ScrollToTop';
import { MotionLazyContainer } from './components/Animate';
import { store } from './redux/store';
import { ImpetusAuthProvider } from './auth/ImpetusAuthProvider';


export default function App() {

  const request = {
    loginHint: "name@example.com",
    scopes: ["User.Read"]
  }

  const { login, error } = useMsalAuthentication(InteractionType.Silent, request);

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Popup, request);
    }
  }, [error]);

  return (
    <ReduxProvider store={store}>
      <ImpetusAuthProvider>
        <HelmetProvider>
          <QueryParamProvider adapter={ReactRouter6Adapter} options={{
            removeDefaultsFromUrl: true,
            enableBatching: true,
          }}>
            <ScrollToTop />
            <MotionLazyContainer>
              <ThemeProvider>
                <ThemeLocalization>
                  <SnackbarProvider>
                    <Outlet />
                  </SnackbarProvider>
                </ThemeLocalization>
              </ThemeProvider>
            </MotionLazyContainer>
          </QueryParamProvider>
        </HelmetProvider>
      </ImpetusAuthProvider>
    </ReduxProvider>
  );
}
