import { BrowserCacheLocation, EventType, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { newUuid } from "src/utils/general";

// Polyfill window.crypto.randomUUID used in msal and not supported by Safari Mobile 12 or lower.
if (!window.crypto || !window.crypto.randomUUID) {
    const generateUUID = (): `${string}-${string}-${string}-${string}-${string}` => newUuid() as any;

    if (!window.crypto) {
        window.crypto = {} as Crypto;
    }

    window.crypto.randomUUID = generateUUID;
}



/**
 * Compare the token issuing policy with a specific policy name
 * @param {object} idTokenClaims - Object containing the claims from the parsed token
 * @param {string} policyToCompare - ID/Name of the policy as expressed in the Azure portal
 * @returns {boolean}
 */
export function compareIssuingPolicy(idTokenClaims: any, policyToCompare: any) {
    const tfpMatches = idTokenClaims.tfp?.toLowerCase() === policyToCompare.toLowerCase();
    const acrMatches = idTokenClaims.acr?.toLowerCase() === policyToCompare.toLowerCase();
    return tfpMatches || acrMatches;
}

export const b2cPolicies = {
    names: {
        signUpSignIn: 'B2C_1_SignUpOrSignInWithPassword',
        forgotPassword: 'B2C_1_reset_v3',
        editProfile: 'B2C_1_edit_profile_v2',
    },
    authorities: {
        signUpSignIn: {
            authority: `${process.env.REACT_APP_ADB2C_AUTHORITY_URI!}/B2C_1_SignUpOrSignInWithPassword`,
        },
        forgotPassword: {
            authority: `${process.env.REACT_APP_ADB2C_AUTHORITY_URI!}/B2C_1_reset_v3`,
        },
        editProfile: {
            authority: `${process.env.REACT_APP_ADB2C_AUTHORITY_URI!}/B2C_1_edit_profile_v2`,
        },
    },
    authorityDomain: process.env.REACT_APP_ADB2C_AUTHORITY_DOMAIN!,
};


export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_ADB2C_CLIENT_ID!, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    /* system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        break;
                    case LogLevel.Info:
                        console.info(message);
                        break;
                    case LogLevel.Verbose:
                        console.debug(message);
                        break;
                    case LogLevel.Warning:
                        console.warn(message);
                        break;
                    default:
                        break;
                }
            },
        },
    }, */
};

const scopes : string[] = ['openid', process.env.REACT_APP_ADB2C_SCOPE!];

export const msalRequest = { scopes };

export const acquireAccessToken = async (msalInstance: IPublicClientApplication) => {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        */
        return null;
    }
    const request = {
        scopes,
        account: activeAccount || accounts[0]
    };

    const authResult = await msalInstance.acquireTokenSilent(request);

    return authResult.accessToken
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const initializeMsalInstance = () => {

    msalInstance.initialize().then(() => {
        // Default to using the first account if no account is active on page load
        if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
            // Account selection logic is app dependent. Adjust as needed for different use cases.
            msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
        }

        // Optional - This will update account state if a user signs in from another tab or window
        msalInstance.enableAccountStorageEvents();

        msalInstance.addEventCallback((event: any) => {
            // console.log(event.eventType)
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                const { account } = event.payload;
                msalInstance.setActiveAccount(account);
            }

            if (
                (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                event.payload.account
            ) {

                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth
                 * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
                 * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
                 * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                 */
                if (event.payload.idTokenClaims?.tfp === b2cPolicies.names.editProfile) {
                    // retrieve the account from initial sing-in to the app
                    const originalSignInAccount = msalInstance
                        .getAllAccounts()
                        .find(
                            (account) =>
                                account?.idTokenClaims?.oid === event.payload.idTokenClaims.oid &&
                                account?.idTokenClaims?.sub === event.payload.idTokenClaims.sub &&
                                account?.idTokenClaims?.tfp === b2cPolicies.names.signUpSignIn
                        );

                    const signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        account: originalSignInAccount,
                    };

                    // silently login again with the signUpSignIn policy
                    console.log("-----instance.ssoSilent(signUpSignInFlowRequest)")
                    msalInstance.ssoSilent(signUpSignInFlowRequest);
                }

                /**
                 * Below we are checking if the user is returning from the reset password flow.
                 * If so, we will ask the user to reauthenticate with their new password.
                 * If you do not want this behavior and prefer your users to stay signed in instead,
                 * you can replace the code below with the same pattern used for handling the return from
                 * profile edit flow
                 */
                if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
                    const signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        scopes: []
                    };

                    console.log("-----instance.loginRedirect(signUpSignInFlowRequest)")
                    msalInstance.loginRedirect(signUpSignInFlowRequest);
                }
            }

            if (event.eventType === EventType.LOGIN_FAILURE) {
                // Check for forgot password error
                // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                    const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.forgotPassword.authority,
                        scopes: [],
                    };
                    console.log("-----instance.loginRedirect(resetPasswordRequest)")
                    msalInstance.loginRedirect(resetPasswordRequest);
                }
            }
        });
    });
};


