import { MsalProvider } from "@azure/msal-react";
import { useEffect } from "react";
import { msalInstance } from "./msalInstance";
import { useAuthContext } from "./useAuthContext";

type AuthProviderProps = {
  children: React.ReactNode;
};

export function ImpetusAuthProvider({ children }: AuthProviderProps) {
  return (
    <MsalProvider instance={msalInstance}>
      <ImpetusAuthProviderChild>
        {children}
      </ImpetusAuthProviderChild>
    </MsalProvider>
  );
}

type ImpetusAuthProviderChildProps = {
  children: React.ReactNode;
};

export function ImpetusAuthProviderChild({ children }: ImpetusAuthProviderChildProps) {
  const { logout } = useAuthContext();
  useEffect(() => {
    const handleLoginRequest = () => {
      logout();
    }

    window.addEventListener('request-login', handleLoginRequest)
    return () => window.removeEventListener('request-login', handleLoginRequest)
  }, [])

  return <>{children}</>;
}
