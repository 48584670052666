import { LazyExoticComponent } from "react"
import { RouteObject } from "react-router"
import { Loadable } from "./Loadable"

type ModuleBasePath = false | string;

interface RouterModuleDeclarator {
    (moduleBasePath: ModuleBasePath, lazyComponentImport: LazyExoticComponent<any>): RouteObject;
}

/**
 * Creates a `RouteObject` for React Router with code-splitting applied to the element
 * 
 * @param moduleBasePath If set to `false` the path will be a catch-all route
 */
export const declareLazyRouterModule: RouterModuleDeclarator = (moduleBasePath, lazyComponentImport) => ({
    path: moduleBasePath === false ? '*' : `${ moduleBasePath }/*`,
    element: <Loadable LazyImport={ lazyComponentImport } />
})